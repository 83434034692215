import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router) {
    console.log('*_* aqui', this.router.url);
    document.body.setAttribute('color-theme', 'dark');
    // this.router.navigate(['home'], { skipLocationChange: true });
  }
}
